import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import './index.less'

function InquiryModal(props) {
  const { visible, handleCancel } = props
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      className="modalInquiry"
      getContainer={false}
      width={600}
    >
      <div className="title">立即咨询</div>
      <img src="https://oss.huizustore.com/517c8bfc1bd64dcb8c1199ceed63ddd5.png" alt="" width={240} height={240} />
      <div className="content">
        微信扫一扫 <br />
        与客服经理一对一沟通开店
      </div>
    </Modal>
  )
}

export default InquiryModal;