import React, { useRef } from 'react';
import { Modal, Input, Form, Button, Toast } from 'antd-mobile';
import axios from 'axios'
import './index.less';
import { Swiper } from 'antd-mobile';

function About(props) {

  const swiper = useRef()

  const showModal = () => {
    Modal.show({
      content: (
        <div className="mobile-business-modal-content">
          <strong>立即预约</strong>
          <div className="close" onClick={(e) => {
            Modal.clear()
          }}></div>
          <Form layout="horizontal" mode='card' onFinish={onFinish} footer={<Button block type="submit" color="danger" style={{ width: '50%', margin: '0 auto'}}>提交</Button>}>
            <Form.Item name="contactName" rules={[{required: true}]}>
              <span>姓<div style={{ opacity: '0', display: 'inline'}}>占位</div>名：</span>
              <Input placeholder='请输入姓名' />
            </Form.Item>
            <Form.Item name="enterpriseName" rules={[{required: true}]}>
              <span>企业名称：</span>
              <Input placeholder='请输入企业名称' />
            </Form.Item>
            <Form.Header />
            <Form.Item name="telephone" rules={[{required: true}]}>
              <span>手机号码：</span>
              <Input placeholder='请输入手机号码' />
            </Form.Item>
            <Form.Item name="categories" rules={[{required: true}]}>
              <span>意向类型：</span>
              <Input placeholder='请输入意向类型' />
            </Form.Item>
          </Form>
        </div>
      ),
      closeOnMaskClick: true,
      className:"mobile-business-modal"
    })
  }

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    submitForm({
      ...values,
      consultType:1,
      consultSource: 1,
    }).then(res => {
      if (res?.data?.code === 1) {
        Modal.clear()
        Toast.show({
          icon: 'success',
          content: res.data.msg,
        })
      } else {
        Toast.show({
          icon: 'fail',
          content: res.data.msg,
        })
      }
    });
  };

  return (
    <div className="about">
      <div className="about-container">
        <div className="about-container-header">
          <div className="about-container-header-top">
            全品类信用免押金租赁平台
          </div>
          <div className="about-container-header-center">
            为消费者和商家带来更高效更智能的租赁服务
          </div>
          <div className="about-container-header-bottom" href="lianxi" onClick={showModal}>
            <a href="#lianxi">商家入驻</a>
          </div>
        </div>
      </div>
      <div className="about-main">
        <div className="about-main-title">关于我们</div>
        <div className="about-main-sub">领先的数字化租赁交易服务提供商</div>
        <img src="https://oss.huizustore.com/c4d10fd622314d849cb113103d215d4b.png" alt="img" className="about-main-img" />
        <div className="about-main-content">惠租（www.huizustore.com)是国内领先的头部信用免押租赁平台，为企业和个人提供一站式租赁服务。惠租全目前已服务超2000万用户，5000＋租赁商户入驻，覆盖全国超25个省份、覆盖城市超200个，累计租出数十万设备，累计为用户减免押金超百亿。惠租始终将服务好用户作为发展的使命，持续帮助商家进行线上线下协同，连接线下实体店和B端、C端用户，提供更加优质的服务环境。</div>
      </div>
      <div className="about-team">
        <div className="about-team-title">我们的团队</div>
        <div className="about-team-content">
          <div>脚本科技（惠租）成立于2019年，</div>
          <div>创始团队主要来自阿里巴巴、支付宝、淘宝、美团等，</div>
          <div>拥有超过10年的交易及营销平台产品经验。</div>
          <div>借力于共享经济的蓬勃发展，</div>
          <div>惠租致力于为消费者提供循环租赁服务，</div>
          <div>助力低碳环保的绿色消费理念。</div>
        </div>
        <div className="about-team-member">
          <div className="about-team-member-item">
            <img src="https://oss.huizustore.com/2dfc5e1a94ca4d7f8423dc5cfd7e18e7.jpg" alt="img" className="about-team-member-item-img"/>
            <div className="about-team-member-item-content">
            <div className="about-team-member-item-content-title">李昱龙</div>
            <div className="about-team-member-item-content-sub">创始人CEO</div>
            <div className="about-team-member-item-content-content">原支付宝交易线产品经理；电子科技大学硕士，有金融学和电子工程双学位。</div>
          </div>
          </div>
          <div className="about-team-member-item">
            <img src="https://oss.huizustore.com/ac76b378653d44ec8305f79ee6cdea4d.jpg" alt="img" className="about-team-member-item-img"/>
            <div className="about-team-member-item-content">
            <div className="about-team-member-item-content-title">杨代明</div>
            <div className="about-team-member-item-content-sub">联合创始人</div>
            <div className="about-team-member-item-content-content">原百度产品专家；电子科技大学硕士，有工商管理和电子工程双学位。</div>
          </div>
          </div>
          <div className="about-team-member-item">
            <img src="https://oss.huizustore.com/0f46a023011d4bc19a3062fa0d98240f.jpg" alt="img" className="about-team-member-item-img"/>
            <div className="about-team-member-item-content">
            <div className="about-team-member-item-content-title">王国丰</div>
            <div className="about-team-member-item-content-sub">研发总监</div>
            <div className="about-team-member-item-content-content">原滴滴架构师，魔筷科技交易线技术负责人，郑州大学学士。</div>
          </div>
          </div>
        </div>
      </div>
      <div className="about-trial" id='huizu'>
        <div className="about-trial-title">相关荣誉</div>
        <div className="about-trial-sub">公司已获得国家高新技术企业、杭州市人工智能<br/> 小镇项目、梦想小镇优质项目、杭州市雏鹰计划等荣誉认证。</div>
        <div className="container">
          <Swiper ref={swiper} loop indicator={() => null} defaultIndex={2} slideSize={60} trackOffset={30} stuckAtBoundary={false} allowTouchMove>
            <Swiper.Item>
              <div className="huizu-item">
                <img src="https://oss.huizustore.com/e6c95e23a5704b339c13526e4e4ea976.png" alt="img"  id="small"  />
              </div>

            </Swiper.Item>
            <Swiper.Item>
              <div className="huizu-item">
                <img src="https://oss.huizustore.com/37d4d848145e463cb77088b484b46178.png" alt="img"    />
              </div>

            </Swiper.Item>
            <Swiper.Item>
            <div className="huizu-item">
              <img src="https://oss.huizustore.com/b359d54ff72948338993799da01a4c78.png" alt="img"  id="small" />
            </div>

            </Swiper.Item>
            <Swiper.Item>
            <div className="huizu-item">
              <img src="https://oss.huizustore.com/61c656cb9420490ab1bfe59e5e3f8453.png" alt="img"   />
            </div>
            </Swiper.Item>
          </Swiper>
        </div>
      </div>


      <div className="about-huizu">
        <div className="about-huizu-title">在惠租</div>
        <div className="about-huizu-sub">让天下的店铺都能做租赁 让租赁没有押金</div>
        <div className="about-huizu-content">
          <img src="https://oss.huizustore.com/b0a90a2bb13f463d8f6538199b3a2c85.png" alt="img" className="about-huizu-content-img" />
          <div className="about-huizu-content-title">租赁用户在惠租</div>
          <div className="about-huizu-content-content">惠租APP及支付宝“惠租”小程序，可以租赁到100＋种类的产品，包括电脑、手机、平板、品牌手表、无人机等。惠租平台真正做到信用免押，随租随用，正品速发，在租前、租中、租后全流程跟进服务，获得真实好用的租赁商品，大幅节省租赁成本支出，上惠租好平台真无忧。</div>
          <div className="about-huizu-content-title">租赁商在惠租</div>
          <div className="about-huizu-content-content">脚本科技旗下拥有惠租及惠租云SaaS，分别为租赁商提供入驻和自建租赁小程序产品，基于长期沉淀的金融级租赁交易能力，为线上线下租赁商提供完整信用租赁解决方案，让天下的店铺都能做租赁，让租赁没有押金。</div>
        </div>
      </div>
      <div className="about-value">
        <div className="about-value-title">我们的价值观</div>
        <div className="about-value-sub">让天下的店铺都能做租赁，让租赁没有押金</div>
        <div className="about-value-content">
          <div className="about-value-content-item">
            <div className="about-value-content-item-title">宗旨</div>
            <div className="about-value-content-item-content">引领新租赁赛道的创新和发展</div>
          </div>
          <div className="about-value-content-item">
            <div className="about-value-content-item-title">理念</div>
            <div className="about-value-content-item-content">消费新选择，信用免押金，让租赁更简单</div>
          </div>
          <div className="about-value-content-item">
            <div className="about-value-content-item-title">价值</div>
            <div className="about-value-content-item-content">为线上线下租赁商提供完整信用租赁解决方案</div>
          </div>
          <div className="about-value-content-item">
            <div className="about-value-content-item-title">使命</div>
            <div className="about-value-content-item-content">帮助天下租赁商和消费者享受更智能的租赁新生活</div>
          </div>
        </div>
      </div>
      

      {/* <Footer /> */}
    </div>
  )
}

export default About;