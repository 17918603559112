import React from 'react';
import { Divider, } from 'antd';
import './index.less'

function Footer() {

  const changePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  return(
    <div className="footer">
      <div className="footer-content">
        <div className="footer-contents-product">
          <div className="product-title">商家产品</div>
          <div className="product-content" id="link" onClick={() => changePages(2)}>商家入驻</div>
          <div className="product-content" id="link" onClick={() => changePages(3)}>租赁小程序</div>
          <div className="product-content" id="link" onClick={() => changePages(4)}>开放平台</div>
          <div className="product-content" id="link" onClick={() => changePages(5)}>锁机</div>
        </div>
        <div className="footer-contents-contact">
          <div className="contact-title">适用场景/行业</div>
          <div className="line-box">
            <div className="line">
              <div className="contact-content">零售门店</div>
              <div className="contact-content">微信私域</div>
              <div className="contact-content">电动车</div>
              <div className="contact-content">景区酒店</div>
            </div>
            <div className="line">
              <div className="contact-content">手机数码</div>
              <div className="contact-content">母婴图书</div>
              <div className="contact-content">支付宝生态</div>
              <div className="contact-content">自助设备</div>
            </div>
            <div className="line">
              <div className="contact-content">家居家电</div>
              <div className="contact-content">游戏电玩</div>
              <div className="contact-content">企业办公</div>
              <div className="contact-content" id="more">更多敬请期待</div>
            </div>
          </div>
        </div>
        <div className="footer-code-left">
          <div className="footer-icon">
            <img src="https://oss.huizustore.com/1f09853a295c4f20ba7eeb1521a15073.png" alt="img" width={103} height={105} />
          </div>
          <div className="footer-icon-tip">客户顾问</div>
        </div>
        <div className="footer-code-center">
          <div className="footer-icon">
            <img src="https://oss.huizustore.com/328ea1ed477547938016771cbee816ae.png" alt="img" width={103} height={105} />
          </div>
          <div className="footer-icon-tip">微信公众号</div>
        </div>
        <div className="footer-code-right">
          <div className="footer-icon">
            <img src="https://oss.huizustore.com/23ee009b924d4eed90cb10c14908d4ca.png" alt="img" width={103} height={105} />
          </div>
          <div className="footer-icon-tip">支付宝生活号</div>
        </div>
        <div className="footer-contents-address">
          {/* <div className="footer-contents-phone">0571—26881669</div> */}
          <div className="footer-contents-company">客服电话：0571—26881669</div>
          <div className="footer-contents-company">人工服务：9:30—18:00</div>
          <div className="footer-contents-company">公司名称：杭州脚本信息技术有限公司</div>
          {/* <div className="footer-contents-company">公司地址：杭州市余杭区良睦路1399号</div> */}
          <div className="footer-contents-company">公司地址：浙江省杭州市余杭区仓前街道文一西路1378号E幢10楼</div>
          <div className="footer-contents-company">商务/渠道及其它合作：business@huizustore.com</div>
        </div>
      </div>
      <Divider className="hr" />
      <div className="footer-footer">
        <div className="footer-left">备案/许可证号：<a href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank">浙ICP备19009847号</a></div>
        <div className="footer-center">Copyright © 惠租电信业务经营许可证：浙B2-20190991</div>
        <div className="footer-right">@2024 杭州脚本信息技术有限公司 版权所有</div>
      </div>
    </div>
  )
}

export default Footer;