import React from 'react';
import { Modal, Swiper } from 'antd-mobile';
import './index.less';

function Platform(props) {
  const showAsk = () => {
    Modal.show({
      content: (
        <div className="mobile-business-modal-content">
          <strong>立即咨询</strong>
          <div className="close" onClick={Modal.clear}></div>
          <img src="https://oss.huizustore.com/3ab3ba5267ca4fd688efe739112943ce.png" alt="huizu" className="ask-img" />
          <div className="ask-content">微信扫一扫，与客服经理一对一咨询开店</div>
        </div>
      ),
      closeOnMaskClick: true,
      className:"mobile-business-modal"
    })
  }

  const headerChangePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  return (
    <div className="mobileContent">
      <Swiper defaultIndex={3} indicatorProps={{style: {"--active-dot-color": '#ED0000', '--active-dot-size': '3px', '--active-dot-border-radius' : '50%'}}} allowTouchMove autoplay loop autoplayInterval={5000} className="homePage-swiper" >
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(1)}>
            <div className="mobileContent-container-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                全品类信用免押金租赁平台
              </div>
              <div className="mobileContent-container-header-center">
                为消费者和商家带来更高效更智能的租赁服务
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">商家入驻</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(2)} >
            <div className="mobileContent-container-header" id="business-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                商家入驻
              </div>
              <div className="mobileContent-container-header-center">
                一分钟免费拥有自己的租赁店铺
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即试用</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(3)}>
            <div className="mobileContent-container-header" id="miniprogram-header" style={{ cursor: 'pointer'}} >
              <div className="mobileContent-container-header-top">
                租赁小程序
              </div>
              <div className="mobileContent-container-header-center">
                您自己品牌的独立支付宝小程序
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi" >
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" >
            <div className="mobileContent-container-header" id="platform-header" >
              <div className="mobileContent-container-header-top">
                开放平台
              </div>
              <div className="mobileContent-container-header-center">
                有效帮助商家快速甄别用户资质，合理把控风险，助力稳健经营
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi" onClick={showAsk}>
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(5)}>
            <div className="mobileContent-container-header" id="lock-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                锁机服务
              </div>
              <div className="mobileContent-container-header-center">
                多种业务模式均可支持
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
      </Swiper>
      <div className="box" id="control">
        <div className="box-title">红盾风控</div>
        <div className="box-des">惠租平台自研风控体系</div>
        <div className="container">
          <div className="control-item">
            <div className="control-item-title">权威数据源</div>
            <div className="control-item-content">惠租平台数据库＋布尔数据＋支付宝风险评级＋央行征信等＝红盾风控数据中心</div>
            <div className="control-item-btn" onClick={showAsk}>立即咨询</div>
          </div>
          <div className="control-item">
            <div className="control-item-title">风控报告</div>
            <div className="control-item-content">重点风险标注、用户基本信息、红盾名单库、法院信息、历史租借记录、外部借款履约信息等类目，超1000条风控信息特征。</div>
            <div className="control-item-btn" onClick={showAsk}>立即咨询</div>
          </div>
        </div>
      </div>
      <div className="box" id="machine">
        <div className="box-title">锁机服务</div>
        <div className="box-des">多种业务模式均可支持</div>
        <div className="container">
          <div className="machine-item">
            <img src="https://oss.huizustore.com/70461e8e9ae84865b13207b9879a3d74.png" alt="img" className="machine-item-img" />
            <div className="machine-item-detail">
              <div className="machine-item-detail-title">发货无忧</div>
              <div className="machine-item-detail-content">锁机服务精准切合商家痛点，发货监管机可以</div>
            </div>
          </div>
          <div className="machine-item">
            <img src="https://oss.huizustore.com/be3ec7d327ff487492bbd429a53e4dfa.png" alt="img" className="machine-item-img" />
            <div className="machine-item-detail">
              <div className="machine-item-detail-title">操作简单</div>
              <div className="machine-item-detail-content">四步简单配置，最快3个工作日完成锁机服务配置，线上进行管理和设置。</div>
            </div>
          </div>
          <div className="machine-item">
            <img src="https://oss.huizustore.com/b246dea5fcec4ed2a447055e4e7b4d5d.png" alt="img" className="machine-item-img" />
            <div className="machine-item-detail">
              <div className="machine-item-detail-title">效益倍增</div>
              <div className="machine-item-detail-content">锁机服务带来的安心保障，店铺的营业额及发货量的提升效果显著。</div>
            </div>
          </div>
        </div>
      </div>
      <div className="box" id="more">
        <div className="box-title">更多服务</div>
        <div className="box-des">人脸识别、OCR、电子签章</div>
        <div className="container">
          <img src="https://oss.huizustore.com/5ea822eb6cef4f84b8c4f7f019a99622.png" alt="img" className="more-img" />
          <div className="more-item">
            <div className="more-item-title">人脸识别身份认证</div>
            <div className="more-item-content">采用人脸，眼纹，证照等多因子认证技术快速得出认证结果，解决惠租平台APP个人身份验证问题</div>
          </div>
          <div className="more-item">
            <div className="more-item-title">OCR</div>
            <div className="more-item-content">基于业界领先OCR识别技术，支持对身份证正反面的全部字段进行结构化识别</div>
          </div>
          <div className="more-item" id="none-border">
            <div className="more-item-title">电子签章</div>
            <div className="more-item-content">电子合同是指用户在惠租平台下单的订单发货后自动签署的区块链存证线上电子合同。</div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Platform;