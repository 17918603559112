import React, { useRef } from 'react';
import { Modal, Swiper, Form, Toast, Input, Button } from 'antd-mobile';
import axios from 'axios';
import './index.less';
const list = [
  {
    img: 'https://oss.huizustore.com/9e9966fcfd194d5296937fa7b27a165f.png',
    title: '趣智数码',
    content: '全品类相机',
  },
  {
    img: 'https://oss.huizustore.com/c0d23027261c4ee2aaec281d58326e18.png',
    title: '槟恒贸易',
    content: '手机租赁',
  },
  {
    img: 'https://oss.huizustore.com/939b8338074a487583cf00fd3120e55a.png',
    title: '大胜租赁',
    content: '电脑租赁',
  },
  {
    img: 'https://oss.huizustore.com/f0e0c20fdba7417dbf569503e45b8c1d.png',
    title: '基原租赁',
    content: '平板租赁',
  },
  {
    img: 'https://oss.huizustore.com/e37a27add2bd4aadb4a81dce785a57f8.png',
    title: '融易租数码',
    content: '无人机租赁',
  },
]
function MiniProgarm(props) {

  const swiper = useRef()
  const listRef = useRef()
  const showAsk = () => {
    Modal.show({
      content: (
        <div className="mobile-business-modal-content">
          <strong>立即咨询</strong>
          <div className="close" onClick={Modal.clear}></div>
          <img src="https://oss.huizustore.com/3ab3ba5267ca4fd688efe739112943ce.png" alt="huizu" className="ask-img" />
          <div className="ask-content">微信扫一扫，与客服经理一对一咨询开店</div>
        </div>
      ),
      closeOnMaskClick: true,
      className:"mobile-business-modal"
    })
  }

  const showModal = () => {
    Modal.show({
      content: (
        <div className="mobile-business-modal-content">
          <strong>立即预约</strong>
          <div className="close" onClick={(e) => {
            Modal.clear()
          }}></div>
          <Form layout="horizontal" mode='card' onFinish={onFinish} footer={<Button block type="submit" color="danger" style={{ width: '50%', margin: '0 auto'}}>提交</Button>}>
            <div className="form_line">
              <span>姓<div style={{ opacity: '0', display: 'inline'}}>占位</div>名：</span>
              <Form.Item name="contactName" rules={[{required: true, message: '请输入姓名'}]}>
                <Input placeholder='请输入姓名' />
              </Form.Item>
            </div>
            <div className="form_line">
              <span>企业名称：</span>
              <Form.Item name="enterpriseName" rules={[{required: true, message: '请输入企业名称'}]}>
                <Input placeholder='请输入企业名称' />
              </Form.Item>
            </div>
            <div className="form_line">
              <span>手机号码：</span>
              <Form.Item name="telephone" rules={[{required: true, message: '请输入手机号码'}]}>
                <Input placeholder='请输入手机号码' />
              </Form.Item>
            </div>
            <div className="form_line">
              <span>意向类型：</span>
              <Form.Item name="categories">
                <Input placeholder='请输入意向类型' />
              </Form.Item>
            </div>
          </Form>
        </div>
      ),
      closeOnMaskClick: true,
      className:"mobile-business-modal"
    })
  }

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    submitForm({
      ...values,
      consultType:1,
      consultSource: 1,
    }).then(res => {
      if (res?.data?.code === 1) {
        Modal.clear()
        Toast.show({
          icon: 'success',
          content: res.data.msg,
        })
      } else {
        Toast.show({
          icon: 'fail',
          content: res.data.msg,
        })
      }
    });
  };

  const headerChangePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  return (
    <div className="mobileContent">
      <Swiper defaultIndex={2} indicatorProps={{style: {"--active-dot-color": '#ED0000', '--active-dot-size': '3px', '--active-dot-border-radius' : '50%'}}} allowTouchMove autoplay loop autoplayInterval={5000} className="homePage-swiper" >
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(1)}>
            <div className="mobileContent-container-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                全品类信用免押金租赁平台
              </div>
              <div className="mobileContent-container-header-center">
                为消费者和商家带来更高效更智能的租赁服务
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">商家入驻</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(2)} >
            <div className="mobileContent-container-header" id="business-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                商家入驻
              </div>
              <div className="mobileContent-container-header-center">
                一分钟免费拥有自己的租赁店铺
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即试用</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container">
            <div className="mobileContent-container-header" id="miniprogram-header" >
              <div className="mobileContent-container-header-top">
                租赁小程序
              </div>
              <div className="mobileContent-container-header-center">
                您自己品牌的独立支付宝小程序
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi" onClick={showAsk}>
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(4)}>
            <div className="mobileContent-container-header" id="platform-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                开放平台
              </div>
              <div className="mobileContent-container-header-center">
                有效帮助商家快速甄别用户资质，合理把控风险，助力稳健经营
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(5)}>
            <div className="mobileContent-container-header" id="lock-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                锁机服务
              </div>
              <div className="mobileContent-container-header-center">
                多种业务模式均可支持
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
      </Swiper>
      <div className="box" id="need">
        <div className="box-title">适用于多种租赁场景和需求</div>
        <div className="box-des">一站式闭环交易能力，金融级的租物交易能力</div>
        <div className="container">
          <div className="need-item">
            <img src="https://oss.huizustore.com/41f87cdb6fdb4ee18bd6a148f48b6974.png" alt="img" className="need-item-img" />
            <div className="need-item-content">招商店铺入驻</div>
          </div>
          <div className="need-item">
            <img src="https://oss.huizustore.com/6a18e4e305a5471f8bd7dc78589a0319.png" alt="img" className="need-item-img" />
            <div className="need-item-content">商品管理</div>
          </div>
          <div className="need-item">
            <img src="https://oss.huizustore.com/5982f9b43c854399875b73da9c6a00f4.png" alt="img" className="need-item-img" />
            <div className="need-item-content">订单管理</div>
          </div>
          <div className="need-item">
            <img src="https://oss.huizustore.com/1e0b11fdcabe43f58ed01f09b39b44d9.png" alt="img" className="need-item-img" />
            <div className="need-item-content">资金结算</div>
          </div>
          <div className="need-item">
            <img src="https://oss.huizustore.com/f95df80f476440a0983e3e96a7a9b090.png" alt="img" className="need-item-img" />
            <div className="need-item-content">渠道分销</div>
          </div>
          <div className="need-item">
            <img src="https://oss.huizustore.com/8d8182e317a643819bfa5edf3cd8016f.png" alt="img" className="need-item-img" />
            <div className="need-item-content">免押金风控</div>
          </div>
          <div className="need-item">
            <img src="https://oss.huizustore.com/3af199a3477c4f18852a8eebd52c1773.png" alt="img" className="need-item-img" />
            <div className="need-item-content">区块链保险</div>
          </div>
          <div className="need-item">
            <img src="https://oss.huizustore.com/40b3a48d21ef4518b566db1893968c92.png" alt="img" className="need-item-img" />
            <div className="need-item-content">司法仲裁</div>
          </div>
          <div className="need-item">
            <img src="https://oss.huizustore.com/73c9d912883a4437b0fab65683b2210d.png" alt="img" className="need-item-img" />
            <div className="need-item-content">租金代扣</div>
          </div>
          <div className="need-item">
            <img src="https://oss.huizustore.com/1a8d03b1e70b4c4ea5fee636d721c49f.png" alt="img" className="need-item-img" />
            <div className="need-item-content">在线客服</div>
          </div>
        </div>
      </div>
      <div className="box" id="self">
        <div className="box-title">0门槛开展自营租赁业务</div>
        <div className="box-des">自建租赁平台</div>
        <img src="https://oss.huizustore.com/cd7e01c2da994baca2e5337b4f44559f.png" alt="img" className="self-img" />
        <div className="self-content">已帮助<span>500+</span>租赁商自建租赁平台</div>
      </div>
      <div className="box" id="solve">
        <div className="box-title">解决方案</div>
        <div className="box-des">支付宝官方认证租赁行业数字化解决方案</div>
        <div className="container">
          <div className="solve-item">
            <img src="https://oss.huizustore.com/c8c63f99624945b5a1cfed1f22496335.png" alt="img" className="solve-item-img"/>
            <div className="solve-item-detail">
              <div className="solve-item-detail-title">技术支持</div>
              <div className="solve-item-detail-content">惠租云SaaS服务企业全程陪跑，从搭建架构到稳定运营全流程服务，助力客户成功。</div>
            </div>
          </div>
          <div className="solve-item">
            <img src="https://oss.huizustore.com/87d442ddc1714851b33036a03b41cb14.png" alt="img" className="solve-item-img"/>
            <div className="solve-item-detail">
              <div className="solve-item-detail-title">运营陪跑</div>
              <div className="solve-item-detail-content">VIP 1对1惠租专业运营团队服务，助您业务增长</div>
            </div>
          </div>
          <div className="solve-item">
            <img src="https://oss.huizustore.com/ee611ebf7dfe4b399623a0981f8a8682.png" alt="img" className="solve-item-img"/>
            <div className="solve-item-detail">
              <div className="solve-item-detail-title">流量红利</div>
              <div className="solve-item-detail-content">支付宝租物流量红利，新消费新人群。</div>
            </div>
          </div>
          <div className="solve-item">
            <img src="https://oss.huizustore.com/76710a1328c84caaab6c70a6ff17db65.png" alt="img" className="solve-item-img"/>
            <div className="solve-item-detail">
              <div className="solve-item-detail-title">定期迭代</div>
              <div className="solve-item-detail-content">低成本建立您自己品牌的应用，定期更新维护，升级小程序服务，运营维护更简单</div>
            </div>
          </div>
        </div>
       
      </div>
      <div className="box" id="solve-main">
        <div className="box-title">为你提供线上运营全链路解决方案</div>
        <div className="box-des">多种业务模式均可支持</div>
        <div className="container">
          <div className="solve-main-item">
            <img src="https://oss.huizustore.com/25977609f2a74622882bef02094201b6.png" alt="img" className="solve-main-item-img" />
            <div className="solve-main-item-detail">
              <div className="solve-main-item-detail-title">自营模式</div>
              <div className="solve-main-item-detail-content">品类自营，出租你的商品，获得长期租金收益</div>
            </div>
          </div>
          <div className="solve-main-item">
            <img src="https://oss.huizustore.com/882ba6d67c664ff8a8b39baaff203a1d.png" alt="photo" className="solve-main-item-img" />
            <div className="solve-main-item-detail">
              <div className="solve-main-item-detail-title">平台模式</div>
              <div className="solve-main-item-detail-content">招募商家入驻你的小程序，助你快速扩充业务品类，带来用户增长</div>
            </div>
          </div>
          <div className="solve-main-item">
            <img src="https://oss.huizustore.com/5a087570580741b8a820df032e370ecd.jpg" alt="img" className="solve-main-item-img" />
            <div className="solve-main-item-detail">
              <div className="solve-main-item-detail-title">分销模式</div>
              <div className="solve-main-item-detail-content">支持招募线上及线下渠道代理，让线上渠道及线下门店为你推广商品，获取佣金收益，支持多级分销</div>
            </div>
          </div>
        </div>
      </div>
      <div className="box" id="shop">
        <div className="box-title">四步快速开店</div>
        <div className="box-des">一站式、支付、流量、免押金风控、司法，你需要的我们都有</div>
        <div className="container">
          <div className="shop-item">
            <img src="https://oss.huizustore.com/c9f5a13d9bd3498fa8c2580a040eb733.png" alt="img"  className="shop-item-img"/>
            <div className="shop-item-detail">
              <div className="shop-item-detail-title">1.确认营业执照</div>
              <div className="shop-item-detail-content">请先确认是否具备营业执照</div>
            </div>
          </div>
          <div className="shop-item">
            <img src="https://oss.huizustore.com/1187d4f71549490a8d129e22abace73f.png" alt="img"  className="shop-item-img"/>
            <div className="shop-item-detail">
              <div className="shop-item-detail-title">2.留下联系方式</div>
              <div className="shop-item-detail-content">为了方便取得联系，请确保您的手机保持畅通</div>
            </div>
          </div>
          <div className="shop-item">
            <img src="https://oss.huizustore.com/c9f5a13d9bd3498fa8c2580a040eb733.png" alt="img"  className="shop-item-img"/>
            <div className="shop-item-detail">
              <div className="shop-item-detail-title">3.客户经理沟通</div>
              <div className="shop-item-detail-content">24小时内客户经理会和您取得联系</div>
            </div>
          </div>
          <div className="shop-item">
            <img src="https://oss.huizustore.com/c9f5a13d9bd3498fa8c2580a040eb733.png" alt="img"  className="shop-item-img"/>
            <div className="shop-item-detail">
              <div className="shop-item-detail-title">4.平台开店成功</div>
              <div className="shop-item-detail-content">畅谈合作意向，助力开店成功</div>
            </div>
          </div>
        </div>
      </div>
      <div className="box" id="huizu">
        <div className="box-title">他们都在用惠租</div>
        <div className="box-des">排名不分先后顺序</div>
        <div className="list">
          <Swiper ref={listRef} defaultIndex={2} loop slideSize={60} indicator={() => null} trackOffset={20} stuckAtBoundary={false} allowTouchMove={false}>
            {
              list.map((item, index) => (
                <Swiper.Item key={index} >
                  <div className="list-item">
                    <img src={item.img} alt="" width={39} height={39} />
                    <div>
                      <div className="list-item-title">{item.title}</div>
                      <div className="list-item-content">{item.content}</div>
                    </div>
                  </div>
                </Swiper.Item>
              ))
            }
          </Swiper>
        </div>
        <div className="container">
        <div className="icon-left" onClick={() => {
          swiper.current.swipePrev()
          listRef.current.swipePrev()
        }}></div>
          <Swiper ref={swiper} loop indicator={() => null} allowTouchMove={false}> 
            <Swiper.Item>

            <div className="huizu-item">
              <img src="https://oss.huizustore.com/1fab8a8fdb8f4637a3e0c043b0807eec.png" alt=""   />
              <div className="detail">
                <div className="huizu-item-title">大胜租赁</div>
                <div className="huizu-item-tag">
                  <div className="huizu-item-tag-item">笔记本租赁</div>
                  <div className="huizu-item-tag-item">台式机租赁</div>
                  <div className="huizu-item-tag-item">企业租赁</div>
                </div>
                <div className="huizu-item-content">大胜租赁电脑店专注于电脑租赁服务，提供多种品牌和配置的电脑设备，满足办公、教育、娱乐等多领域需求。提供灵活的租赁期限和专业的技术支持，致力于为客户或企业提供最优质的电脑租赁体验。通过线上线下推广策略，大胜租赁电脑店年终用户累计超过1万+，累计下单量突破3万单。且在年末促销活动和企业需求的推动下，年度GMV稳步增长至450万元人民币。</div>
                <div className="huizu-item-count">
                  <div className="huizu-item-count-item"><strong>10000</strong>+<br/>用户数</div>
                  <div className="huizu-item-count-item"><strong>3000</strong>+<br/>月度订单</div>
                </div>
              </div>
            </div>
            </Swiper.Item>
            <Swiper.Item>
            <div className="huizu-item">
              <img src="https://oss.huizustore.com/b8c3f6d3ce254d6f95093d7f9de1e479.png" alt=""   />
              <div className="detail">
                <div className="huizu-item-title">基原租赁</div>
                <div className="huizu-item-tag">
                  <div className="huizu-item-tag-item">平板租赁</div>
                  <div className="huizu-item-tag-item">二手平板全品类</div>
                  <div className="huizu-item-tag-item">优质商家</div>
                </div>
                <div className="huizu-item-content">槟恒贸易租赁店专注于手机租赁服务，提供最新款智能手机供您选择，并为您提供灵活的租赁期限和专业的技术支持。我们致力于为客户打造便捷、经济的手机使用体验。近一年来，基原租赁租赁的GMV增长势头强劲，从80万元人民币迅速增长至140万元人民币，增长率高达75%。这一成绩的背后，是对客户需求的深入洞察，以及对服务品质的持续追求。我们不断优化租赁流程，提升客户体验，赢得了广大客户的信赖与好评。</div>
                <div className="huizu-item-count">
                  <div className="huizu-item-count-item"><strong>8000</strong>+<br/>用户数</div>
                  <div className="huizu-item-count-item"><strong>1300</strong>+<br/>月度订单</div>
                </div>
              </div>
            </div>

            </Swiper.Item>
            <Swiper.Item>
              <div className="huizu-item">
                <img src="https://oss.huizustore.com/19dc37b582e94f0da9e32b3b7ce2b38d.png" alt=""   />
                <div className="detail">
                  <div className="huizu-item-title">融易租数码</div>
                  <div className="huizu-item-tag">
                    <div className="huizu-item-tag-item">无人机租赁</div>
                    <div className="huizu-item-tag-item">年度高赞商家</div>
                    <div className="huizu-item-tag-item">优质商家</div>
                  </div>
                  <div className="huizu-item-content">融易租无人机租赁专注于为航拍爱好者、专业摄影师、入门新手等提供高效、可靠的无人机租赁服务。拥有多款先进的无人机设备，满足不同领域的租赁需求。在过去的一年里，翱翔无人机租赁的业务规模不断扩大，GMV也实现了令人瞩目的增长，从20万元人民币增长至65万元人民币，增长率高达225%。未来，融易租无人机租赁将继续秉持客户至上的原则，不断优化服务流程，提升客户满意度。</div>
                  <div className="huizu-item-count">
                    <div className="huizu-item-count-item"><strong>4000</strong>+<br/>用户数</div>
                    <div className="huizu-item-count-item"><strong>4000</strong>+<br/>月度订单</div>
                  </div>
                </div>
              </div>

            </Swiper.Item>
            <Swiper.Item>
            <div className="huizu-item">
              <img src="https://oss.huizustore.com/a912b93c574b411e93dcd96e50eb410d.png" alt=""   />
              <div className="detail">
                <div className="huizu-item-title">趣智数码</div>
                <div className="huizu-item-tag">
                  <div className="huizu-item-tag-item">全品类相机</div>
                  <div className="huizu-item-tag-item">镜头租赁</div>
                  <div className="huizu-item-tag-item">优质商家</div>
                </div>
                <div className="huizu-item-content">趣智数码相机租赁专注于入门及高端相机租赁服务，拥有丰富的相机型号和专业的租赁方案，满足不同摄影爱好者的需求。趣智数码相机租赁近年来业务规模不断扩大，租赁设备种类和数量持续增长。近一年，我们的GMV实现了显著增长，从35万元人民币增长至86万元人民币，增长率高达145%。这一增长主要得益于我们与各大相机供应商的紧密合作，确保了租赁设备的库存品质和多样性。同时，我们不断优化租赁策略和服务流程，提高了客户满意度和复购率，进一步推动了GMV的增长。</div>
                <div className="huizu-item-count">
                  <div className="huizu-item-count-item"><strong>5000</strong>+<br/>用户数</div>
                  <div className="huizu-item-count-item"><strong>800</strong>+<br/>月度订单</div>
                </div>
              </div>
            </div>

            </Swiper.Item>
            <Swiper.Item>
            <div className="huizu-item">
              <img src="https://oss.huizustore.com/4660179aca7a45b0927a2b85aac0b00c.png" alt=""   />
              <div className="detail">
                <div className="huizu-item-title">槟恒贸易</div>
                <div className="huizu-item-tag">
                  <div className="huizu-item-tag-item">手机租赁</div>
                  <div className="huizu-item-tag-item">手表租赁</div>
                  <div className="huizu-item-tag-item">优质商家</div>
                </div>
                <div className="huizu-item-content">槟恒贸易租赁店专注于手机租赁服务，提供最新款智能手机供您选择，并为您提供灵活的租赁期限和专业的技术支持。我们致力于为客户打造便捷、经济的手机使用体验。近一年，槟恒贸易手机租赁店的GMV实现了1000%的快速增长，年度发货GMV4000万元。这主要得益于我们不断优化服务流程、提升客户满意度以及推出具有竞争力的租赁策略。我们与各大手机品牌建立了紧密的合作关系，确保提供的手机设备均为最新款、高品质的产品。</div>
                <div className="huizu-item-count">
                  <div className="huizu-item-count-item"><strong>30000</strong>+<br/>用户数</div>
                  <div className="huizu-item-count-item"><strong>8000</strong>+<br/>月度订单</div>
                </div>
              </div>
            </div>
            </Swiper.Item>
          </Swiper>
        <div className="icon-right" onClick={() => {
          swiper.current.swipeNext()
          listRef.current.swipeNext()
        }}></div>
        </div>
      </div>
      <div className="box" id="comit">
        <div className="box-title">立即扫码咨询</div>
        <div className="box-des">领取您的专属解决方案</div>
        <div className="container">
          <div className="img-box">
            <img src="https://oss.huizustore.com/3ab3ba5267ca4fd688efe739112943ce.png" alt="img" className="img" />
          </div>
          <div className="title">马上扫码添加客户经理</div>
          <div className="sub">或者您也可以先试用</div>
          <div className="btn" onClick={showModal}>免费试用</div>
        </div>
       
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default MiniProgarm;